import React from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { Link as LinkG } from 'gatsby';
import styled from 'styled-components';

export default function ProjectsBackButton() {
  return (
    <BackButton to='/#projects'>
      <FaChevronLeft />
      <p>Πισω</p>
    </BackButton>
  );
}

const BackButton = styled(LinkG)`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
  gap: 1rem;
  position: sticky;
  top: 2rem;
  width: 9rem;
  padding: 1rem;
  z-index: 5;
  &:hover > svg {
    transform: translateX(-0.625rem);
  }
  svg {
    transition-duration: 0.2s;
  }

  p {
    text-transform: uppercase;
    font-weight: 700;
  }
`;
