import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

export default function NextProject(props) {
  return (
    <NextProjectSection>
      <div className='section-inner'>
        <Link to={props.nextLink}>
          <p>Επόμενο Project</p>
          <NextProjectHeadings>
            <NextProjectHeadingsCat>
              <h3>{props.nextTitle}</h3>
              <p>{props.nextCategory}</p>
            </NextProjectHeadingsCat>
            <p>{props.nextSubtitle}</p>
          </NextProjectHeadings>
          <GatsbyImage image={props.nextImage} alt={props.nextAlt} />
        </Link>
      </div>
    </NextProjectSection>
  );
}

const NextProjectSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0;

  img {
    margin-bottom: -0.25rem;
  }
  a {
    text-decoration: none;
    color: #000;
  }
`;

const NextProjectHeadings = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3rem;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
  }
  p {
    font-weight: 700;
  }
`;

const NextProjectHeadingsCat = styled.div`
  p {
    color: #46da92;
    font-weight: 600;
  }
`;
