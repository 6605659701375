import React from 'react';
import styled from 'styled-components';
import CurrentProjectDescription from './CurrentProjectDescription';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function CurrentProject(props) {
  return (
    <CurrentProjectSection>
      <div className='section-inner'>
        <CurrentProjectHeadings>
          <CurrentProjectHeadingsCat>
            <h1>{props.title}</h1>
            <p>{props.category}</p>
          </CurrentProjectHeadingsCat>
          <p>{props.subtitle}</p>
        </CurrentProjectHeadings>
        <GatsbyImage
          image={props.featuredImage}
          alt={props.featuredAlt}
        ></GatsbyImage>
        <CurrentProjectDescription
          website={props.website}
          description={props.description}
        />
      </div>
    </CurrentProjectSection>
  );
}

const CurrentProjectSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0;
  .gatsby-image-wrapper {
    margin: 0 -5.313rem;
  }
`;

const CurrentProjectHeadings = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5.5rem 0 3rem 0;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
  }
  p {
    font-weight: 700;
  }
`;

const CurrentProjectHeadingsCat = styled.div`
  p {
    color: #46da92;
    font-weight: 600;
  }
`;
