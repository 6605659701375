import React from 'react';
import styled from 'styled-components';

export default function ProjectDescription(props) {
  return (
    <ProjectDescriptionWrap>
      {props.website ? (
        <a href={props.website} target='_blank' rel='noreferrer'>
          VISIT WEBSITE
        </a>
      ) : (
        ''
      )}

      <p>{props.description}</p>
    </ProjectDescriptionWrap>
  );
}

const ProjectDescriptionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  align-self: center;
  gap: 1.875rem;
  margin: 1.688rem 0 5rem 0;
  @media screen and (max-width: 960px) {
    width: 70%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
  }
  a {
    text-decoration: none;
    color: #000;
    font-weight: 600;
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      background: #000;
      transition: width 0.3s;
    }
    &:hover {
      &::after {
        width: 100%;
        transform: width 0.3s;
      }
    }
  }
  p {
    font-weight: 700;
  }
`;
