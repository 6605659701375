import React from 'react';
import CurrentProject from '../components/projects/CurrentProject';
import NextProject from '../components/projects/NextProject';
import CallToAction from '../components/global/CallToAction';
import ContactForm from '../components/global/ContactForm';
import ProjectLayout from '../layouts/ProjectLayout';
import { graphql } from 'gatsby';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import Seo from '../components/global/Seo';

export default function Silkfab({ data }) {
  const {
    title,
    category,
    subtitle,
    description,
    website,
    nextTitle,
    nextCategory,
    nextSubtitle,
    nextLink,
    featuredAlt,
    nextAlt,
  } = data.markdownRemark.frontmatter;
  const featuredImage = getImage(data.markdownRemark.frontmatter.featured);
  const nextImage = getImage(data.markdownRemark.frontmatter.next);
  return (
    <ProjectLayout>
      <Seo title={title} image={featuredImage} />
      <CurrentProject
        title={title}
        category={category}
        subtitle={subtitle}
        description={description}
        website={website}
        featuredImage={featuredImage}
        featuredAlt={featuredAlt}
      />
      <main className='project__images-wrap'>
        <StaticImage
          src='../images/projects/silkfab/silkfab-page-1.png'
          placeholder='blurred'
          alt='Επίδειξη branding και ιστοσελίδας Silkfab 1'
          layout='constrained'
          width={634}
          height={84}
        />
        <StaticImage
          src='../images/projects/silkfab/silkfab-page-2.png'
          placeholder='blurred'
          alt='Επίδειξη branding και ιστοσελίδας Silkfab 2'
        />
        <StaticImage
          src='../images/projects/silkfab/silkfab-page-3.png'
          placeholder='blurred'
          alt='Επίδειξη branding και ιστοσελίδας Silkfab 3'
        />
        <StaticImage
          src='../images/projects/silkfab/silkfab-page-4.png'
          placeholder='blurred'
          alt='Επίδειξη branding και ιστοσελίδας Silkfab 4'
        />
        <StaticImage
          src='../images/projects/silkfab/silkfab-page-5.png'
          placeholder='blurred'
          alt='Επίδειξη branding και ιστοσελίδας Silkfab 5'
        />
      </main>
      <CallToAction />
      <ContactForm />
      <NextProject
        nextTitle={nextTitle}
        nextCategory={nextCategory}
        nextSubtitle={nextSubtitle}
        nextImage={nextImage}
        nextLink={nextLink}
        nextAlt={nextAlt}
      />
    </ProjectLayout>
  );
}

export const query = graphql`
  query SilkfabData {
    markdownRemark(frontmatter: { title: { eq: "Silkfab" } }) {
      frontmatter {
        title
        category
        website
        subtitle
        description
        link
        featuredAlt
        nextTitle
        nextCategory
        nextSubtitle
        nextLink
        nextAlt
        featured {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              quality: 80
            )
          }
        }
        next {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              quality: 80
            )
          }
        }
      }
    }
  }
`;
